import './App.css';
import React, {useState, useEffect} from 'react'
import { request, gql } from 'graphql-request'
import _ from 'lodash';
import Web3 from 'web3'; 

import ReactTable from "react-table";
import "react-table/react-table.css";

const web3 = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/347399e3bb86482f84e8e92cf90a9c64"));

function App() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(0);

  const [totals, setTotals] = useState({
    totalAuctionsSold: 0,
    totalUniqueCryptoKittiesSold: 0,
    totalEtherRevenue: 0
  });

  // useEffect(() => {
  //   console.log("LOADING CHANGED", loading, pages);
  //   if(loading === false){
  //     setPages(pages);
  //   }
    
  // }, [loading, pages])

  useEffect(() => {
    const aggregationQuery = gql`
      {
        aggregation(id: "1") {
          totalAuctions
          totalAuctionsSold
          totalAuctionsCancelled
          totalUniqueCryptoKittiesAuctioned
          totalUniqueCryptoKittiesSold
          totalEtherRevenue
        }
      }
    `
    request('https://api.thegraph.com/subgraphs/name/nieldlr/cryptokitties-sales', aggregationQuery)
    .then((data) => {
      setTotals({
        totalAuctionsSold: data.aggregation.totalAuctionsSold,
        totalUniqueCryptoKittiesSold: data.aggregation.totalUniqueCryptoKittiesSold,
        totalEtherRevenue: parseFloat(web3.utils.fromWei(data.aggregation.totalEtherRevenue), 10).toFixed(2)
      })

      setPages(parseInt(data.aggregation.totalAuctionsSold/25, 10)+1)
    });
  }, [])

  function fetchChartData(state, instance) {
    // console.log(state, instance);
    setLoading(true);

    let additionalFilter = '';
    let orderBy = 'soldPrice';
    let orderDirection = 'desc';
    const skip = state.page*state.pageSize;

    if(state.sorted.length > 0) {
      console.log( state.sorted[0].id);
      orderDirection = state.sorted[0].desc ? 'desc' : 'asc';
      orderBy = state.sorted[0].id;
      // fetchUrl = `${fetchUrl}&sortBy=${state.sorted[0].id}&direction=${state.sorted[0].desc ? 'desc' : 'asc'}`;
    }

    if(state.filtered.length > 0) {
      if(state.filtered[0].id === "cryptoKitty") {
        additionalFilter = `${additionalFilter}, cryptoKitty: "${state.filtered[0].value}"`
      }
    }

    const tableQuery = gql`
      {
        auctions(first: ${state.pageSize}, skip: ${skip}, orderBy: ${orderBy}, orderDirection: ${orderDirection}, where: {state: "sold" ${additionalFilter}}) {
          id
          soldPrice
          endedAt
          winner
          cryptoKitty {
            id
          }
          transactions {
            id
            type
          }
        }
      }
    `

    request('https://api.thegraph.com/subgraphs/name/nieldlr/cryptokitties-sales', tableQuery)
    .then((resp) =>{
      let parsed = [];
      for(let i=0; i<resp.auctions.length; i++) {
        let auction = resp.auctions[i];
        let row = {
          cryptoKitty: auction.cryptoKitty.id,
          soldPrice: auction.soldPrice,
          endedAt: auction.endedAt,
          tx: _.filter(auction.transactions, (tx)=>{ return tx.type === "sold";})[0].id
        }
        parsed.push(row);
      }

      setItems(parsed);
      setLoading(false);
    })
  }

  return (
      <div class="container">
        <div class="sales-container">
          <h1 className="title">💰 <a href="https://www.cryptokitties.co/" target="_blank" rel="noopener noreferrer">CryptoKitties</a> Sales 💰</h1>
          <div className="made-by">~ Made by <a href="https://twitter.com/nieldlr" target="_blank" rel="noopener noreferrer">@nieldlr</a> 🐈 ~ </div>
          <ul>
          <li>Total Succesful Sales: {totals.totalAuctionsSold}</li>
          <li>Total Unique CryptoKities Sold: {totals.totalUniqueCryptoKittiesSold}</li>
          <li>Total Ether Revenue: {totals.totalEtherRevenue}</li>
          </ul>
          <div className="clear"></div>
          <ReactTable
            data={items}
            pages={pages}
            loading={loading}
            manual
            showPageSizeOptions={false}
            onFetchData={_.debounce(fetchChartData, 500)}
            columns={[
              {
                Header: "Kitty Info",
                columns: [
                  // {
                  //   Header: "Rank",
                  //   accessor: "rank"
                  // },
                  {
                    id: "cryptoKitty",
                    Header: "ID",
                    filterable: true,
                    Cell: row => (
                      <a href={`https://www.cryptokitties.co/kitty/${row.value}`} target="_blank" rel="noopener noreferrer">{row.value}</a>
                      ),
                    accessor: d => parseInt(d.cryptoKitty, 10)
                  }
                ],
              },
              {
                Header: "Pricing",
                columns: [
                  {
                    id: "soldPrice",
                    Header: "Ether",
                    Cell: row=> (
                      <span>Ξ {row.value}</span>
                    ),
                    accessor: d => parseFloat(web3.utils.fromWei(d.soldPrice+"", 'ether'), 10).toFixed(3)
                  },
                  // {
                  //   id: "priceUSD",
                  //   Header: "USD (at time of sale)",
                  //   Cell: row=> (
                  //     <span>${row.value}</span>
                  //   ),
                  //   accessor: d => parseFloat(parseFloat(d.soldPrice, 10).toFixed(2), 10)
                  // },
                  // {
                  //   id: "priceUSDCurrent",
                  //   Header: "USD (current value)",
                  //   Cell: row=> (
                  //     <span>${row.value}</span>
                  //   ),
                  //   accessor: d => parseFloat((parseFloat(web3.utils.fromWei(d.returnValues[1]+"", 'ether'), 10)*this.state.exchangeRate).toFixed(2), 10)
                  // },
                ],
              },
              {
                Header: "Details",
                columns: [
                  {
                    id: "endedAt",
                    Header: "Sale Date",
                    Cell: row=> (
                      new Date(row.value).toLocaleString()
                    ),
                    accessor: d => d.endedAt*1000
                  },
                  {
                    id: "tx",
                    sortable: false,
                    Header: "Transaction",
                    Cell: row => (
                      <a href={`https://www.etherscan.io/tx/${row.value}`} target="_blank" rel="noopener noreferrer">View on Etherscan</a>
                      ),
                    accessor: d => d.tx
                  },
                ],
              }

            ]}
            defaultPageSize={25}
            className="-striped -highlight"
          />
        </div>
      </div>
    )
}

export default App;
